/* istanbul ignore file */

import { get } from 'js-cookie';

import { isIE11 } from '../globals';
import { domReady, injectScriptTag } from '../helpers/dom';

function getInjectionUrl(): Promise<string> {
	// eslint-disable-next-line consistent-return
	const chatbotInjectorUrl = CHATBOT_INJECTOR_URL;
	const injectionVersionOverride = get('injectorVersionOverride');

	if (injectionVersionOverride) {
		return Promise.resolve(
			chatbotInjectorUrl.replace('{BUILD_VERSION}', injectionVersionOverride)
		);
	}

	return Promise.resolve(
		chatbotInjectorUrl.replace('{BUILD_VERSION}', BUILD_VERSION)
	);
}

async function inject() {
	const injectionUrl = await getInjectionUrl();
	if (injectionUrl) {
		injectScriptTag(injectionUrl, 'chatbot-injector');
	}
}

if (!isIE11) {
	domReady(inject);
}
