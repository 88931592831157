export function domReady(fn) {
	if (
		document.attachEvent
			? document.readyState === 'complete'
			: document.readyState !== 'loading'
	) {
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}

export function injectScriptTag(src: string, id: string): Promise<boolean> {
	// eslint-disable-next-line consistent-return
	return new Promise((resolve, reject) => {
		if (document.getElementById(id)) {
			// Already exists
			return resolve(true);
		}
		const allScripts = document.getElementsByTagName('script');
		const lastScriptTag = document.getElementsByTagName('script')[
			allScripts.length - 1
		];
		const scriptTag = document.createElement('script');
		scriptTag.id = id; // To avoid inserting the same thing more then once
		scriptTag.onload = function () {
			return resolve(true);
		};
		scriptTag.onerror = (error) => {
			return reject(error);
		};
		scriptTag.src = src;
		if (lastScriptTag && lastScriptTag.parentNode) {
			lastScriptTag.parentNode.insertBefore(
				scriptTag,
				lastScriptTag.nextSibling
			);
		}
	});
}

export function writeStyleTag(css: string): void {
	// eslint-disable-next-line consistent-return
	const head = document.head || document.getElementsByTagName('head')[0];
	const style = document.createElement('style');

	head.appendChild(style);
	style.appendChild(document.createTextNode(css));
}

export function injectStyleTag(href: string, id: string): Promise<boolean> {
	// eslint-disable-next-line consistent-return
	return new Promise((resolve, reject) => {
		if (document.getElementById(id)) {
			// Already exists
			return resolve(true);
		}
		const headEl = document.getElementsByTagName('head')[0];
		const linkTag = document.createElement('link');
		linkTag.id = id; // To avoid inserting the same thing more then once
		linkTag.rel = 'stylesheet';
		linkTag.type = 'text/css';
		linkTag.onload = function () {
			return resolve(true);
		};
		linkTag.onerror = (error) => {
			return reject(error);
		};
		linkTag.href = href;
		linkTag.media = 'all';
		headEl.appendChild(linkTag);
	});
}

/**
 * Create a DOM element from a string without
 * having to append to a containing <div>
 *
 * @param {String} e.g. `<div><span>Hello World</span></div> Foo bar`
 * @return {Element}
 */
export function htmlToFragment(htmlString: string): DocumentFragment {
	const fragment = document.createDocumentFragment();
	const wrapper = document.createElement('div');
	wrapper.innerHTML = htmlString;

	Array.from(wrapper.childNodes).forEach((child) =>
		fragment.appendChild(child)
	);

	return fragment;
}

export function waitForAddedNode(params: {
	query: string;
	parent: HTMLElement;
	recursive: boolean;
	done: (el: HTMLElement) => void;
}): void {
	if (!window.MutationObserver) {
		return;
	}

	const docMutationObserver = new MutationObserver(() => {
		const el = params.parent.querySelector(params.query);
		if (el) {
			params.done(el as HTMLElement);

			if (docMutationObserver && docMutationObserver.disconnect) {
				// tidy up the mutation observer
				docMutationObserver.disconnect();
			}
		}
	});

	docMutationObserver.observe(params.parent || document, {
		subtree: !!params.recursive,
		childList: true,
	});
}
